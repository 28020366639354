<template>
  <v-container fluid
    v-if="openForm"
  >
    <v-row justify="center">
      <v-dialog
        v-model="openForm"
        max-width="500"
        persistent
        transition="dialog-bottom-transition"
      >
        <v-card
          class="pa-5"
          align="center"
        >
          <v-card-title class="mb-5 d-flex flex-column justify-center">
            <h4>{{ castData.name }}さんの一括{{ formTitle }}</h4>
            <icon-info icon="lightbulb-on-outline" class="mt-5 text-subtitle-1" :square="true">
              日付を２つクリックして範囲指定してください
            </icon-info>
          </v-card-title>

          <v-form ref="form">
            <v-row justify="center">
              <v-card-actions>
                <date-picker
                  v-model="datePickerRange"
                  mode="date"
                  color="purple"
                  :is-dark="$vuetify.theme.dark"
                  is-range
                />
              </v-card-actions>
            </v-row>

            <p class="mt-5"><v-icon class="mr-2">mdi-calendar-range</v-icon>{{ formatDateRange }}</p>

            <p class="mt-7 mb-5"><v-icon class="mr-2">mdi-progress-clock</v-icon>出勤時間</p>

            <v-row justify="center">
              <v-card-actions>
                <template>
                  <time-picker
                    v-model="inTime"
                    :hour-range="shiftHourRange"
                    format="HH:mm"
                    placeholder="開始"
                    hour-label="時"
                    minute-label="分"
                    minute-interval="30"
                    auto-scroll
                    close-on-complete
                    hide-disabled-hours
                    hide-disabled-minutes
                    hide-clear-button
                  ></time-picker>
                </template>
                <span class="mx-3"> ~ </span>
                <template>
                  <time-picker
                    v-model="outTime"
                    :hour-range="shiftHourRange"
                    format="HH:mm"
                    placeholder="終了"
                    hour-label="時"
                    minute-label="分"
                    minute-interval="30"
                    auto-scroll
                    close-on-complete
                    hide-disabled-hours
                    hide-disabled-minutes
                    hide-clear-button
                  ></time-picker>
                </template>
              </v-card-actions>
            </v-row>

            <v-card-actions class="mt-5">
              <v-spacer></v-spacer>
              <v-btn
                depressed
                color="primary"
                @click="formSubmitted"
              >
                登録
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="close"
              >
                閉じる
              </v-btn>
            </v-card-actions>
          </v-form>

          <!-- ローダー -->
          <v-progress-linear
          :active="loading"
          :indeterminate="loading"
          absolute top
          color="primary"
          ></v-progress-linear>
        </v-card>
      </v-dialog>
    </v-row>

    <!-- バリデーションアラート -->
    <v-snackbar
      v-model="snackbar.open"
      :timeout="3000"
      :color="snackbar.color"
      top
    >
      {{ snackbar.message }}
    </v-snackbar>

  </v-container>
</template>

<script>
import moment from 'moment'
import 'moment/locale/ja'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'

import $literals from '@/literals.js'
import { BizHour } from '@/module.js'
import IconInfo from '@/components/_IconInfo.vue'

export default {
  components: {
    'date-picker': DatePicker,
    'time-picker': VueTimepicker,
    'icon-info': IconInfo,
  },

  props: {
    apiAdmin: {
      type: String,
      required: true
    },
    shopData: {
      type: Object,
      required: true
    },
    shiftHourRange: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      castData: {},
      datePickerRange: {},
      formTitle: '',
      inTime: '',
      outTime: '',
      openForm: false,
      formType: '',
      comeBack: {},
      submitCallback: null,
      loading: false,
      snackbar: {open: false, color: 'primary', message: ''},
      bizHour: new BizHour(this.shopData)
    }
  },

  computed: {
    formatDateRange() {
      const startDate = moment(this.datePickerRange.start).format('M/D (dd)')
      const endDate = moment(this.datePickerRange.end).format('M/D (dd)')
      return startDate + ' ~ ' + endDate
    }
  },

  created() {
    //デートピッカー表示用の日時セット
    this.setDatePickerRange()
  },

  watch: {},

  methods: {
    //デートピッカー表示用の日時セット
    setDatePickerRange() {
      this.datePickerRange = {
        start: moment(new Date()).format('YYYY-MM-DD'),
        end: moment(new Date()).add(6, 'd').format('YYYY-MM-DD')
      }
    },

    open(handdownData) {
      this.formType = handdownData.formType
      this.submitCallback = handdownData.submitCallback
      this.comeBack = handdownData.comeBack
      this.castData = handdownData.updateData
      this.inTime = this.bizHour.openingHour
      this.outTime = this.bizHour.closingHour
      this.formTitle = this.formType === 'create' ? '出勤登録' : '出勤削除'
      this.openForm = true
    },

    close() {
      this.submitCallback = null
      this.loading = false
      this.openForm = false
    },

    formSubmitted() {
      const inHourNum = parseInt(this.inTime.split(':')[0])
      const outHourNum = parseInt(this.outTime.split(':')[0])

      //時刻バリデーション
      if ( inHourNum == outHourNum ||
          (inHourNum < outHourNum && inHourNum <= this.bizHour.closingHourNum && outHourNum >= this.bizHour.openingHourNum) ||
          (
            inHourNum > outHourNum && ((inHourNum >= this.bizHour.openingHourNum && outHourNum >= this.bizHour.openingHourNum) ||
            (inHourNum <= this.bizHour.closingHourNum && outHourNum <= this.bizHour.closingHourNum))
          )
      ) {
        this.snackbar = {...{color:'warning', message: $literals.MESSAGE.validationStartEndTime, open: true}}
        return
      }

      this.loading = true

      const fromDate = moment(this.datePickerRange.start).format('YYYY-MM-DD')
      const toDate = moment(this.datePickerRange.end).format('YYYY-MM-DD')

      const formData = new FormData()
      formData.append('fromDate', fromDate)
      formData.append('toDate', toDate)
      formData.append('inTime', this.inTime)
      formData.append('outTime', this.outTime)

      this.comeBack.cast_id = this.castData.cast_id
      this.comeBack.fromDate = fromDate
      this.comeBack.toDate = toDate
      this.comeBack.inTime = this.inTime
      this.comeBack.outTime = this.outTime

      if (this.submitCallback) {
        this.submitCallback(formData, this.comeBack)
      }
      this.close()
    }
  }
}
</script>

<style scoped>
>>> .v-dialog {
  overflow-y: inherit !important;
}

/* date-picker用 */
.vc-container {
  border: 1px solid #eee;
  border-top: 2px solid #aaa;
  border-left: 2px solid #aaa;
  border-radius: 3px !important;
}

/* time-picker */
>>> .vue__time-picker {
  width: unset;
}
>>> .vue__time-picker input.display-time {
  width: 110px;
  height: 35px;
  font-size: 1.2em;
  text-align: center;
  border: none;
  border-radius: 4px;
}
.theme--light >>> .vue__time-picker input.display-time {
  color: var(--content-bg-dark-diff);;
  background-color: var(--content-bg-diff);
}
.theme--dark >>> .vue__time-picker input.display-time {
  color: var(--content-bg-diff);
  background-color: var(--content-bg-dark-diff);
}
>>> .vue__time-picker .drop-down,
>>> .vue__time-picker .select-list {
  width: 140px;
  height: 200px;
  border-radius: 5px;
}
>>> .vue__time-picker .dropdown ul li:not([disabled]).active {
  background: var(--v-primary-base);
}
</style>
