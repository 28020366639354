<template>
  <v-container fluid class="content-wrap pa-5">
    <!-- コンテンツヘッダー -->
    <info-bar
      :btns="[
        {label:'1週間', icon:'arrow-left-bold', tip:'一週間前のシフト', event:'weekBackward'},
        {label:'1週間', icon:'arrow-right-bold', tip:'一週間後のシフト', event:'weekForward'},
        {label:'', icon:'arrow-left-bold-box-outline', tip:'画面左スクロール', event:'scrollLeft'},
        {label:'', icon:'arrow-right-bold-box-outline', tip:'画面右スクロール', event:'scrollRight'},
      ]"
      @weekBackward="weekNation('backward')"
      @weekForward="weekNation('forward')"
      @scrollLeft="scrollLeft"
      @scrollRight="scrollRight"
    >
      <template v-slot:content-info>
        {{ dateForHeaderLable }} 出勤キャスト：{{ castsInCount }}名
        <v-btn
          class="ml-5"
          :disabled="!changedOrder"
          depressed small
          color="accent"
          @click="$refs.castsInDate.updateOrder()"
        >
          並び登録
          <v-icon small>mdi-sort-ascending</v-icon>
        </v-btn>
      </template>
    </info-bar>

    <!-- 出勤情報 -->
    <v-row no-gutters>
      <v-col cols="12" sm="6"
        class="d-flex align-stretch elevation-1 rounded"
        :class="$vuetify.theme.dark ? 'grey darken-4' : 'grey lighten-4'"
      >
        <casts-in-date
          ref="castsInDate"
          :apiAdmin="apiAdmin"
          :shopData="shopData"
          :castsData="casts"
          :initDate="initialCastsInDate"
          height="405"
          @sorted="changedOrder = true"
          @updatedOrder="changedOrder = false"
          @loadedCastsInDate="updateCastsInCount($event)"
          @reset="$emit('reset')"
        ></casts-in-date>
      </v-col>
      <v-col cols="12" sm="6">
        <shift-calendar
          ref="shiftCalendar"
          :apiAdmin="apiAdmin"
          :shopData="shopData"
          @changeCalendarDate="changeCastsInDate($event)"
          @reset="$emit('reset')"
        ></shift-calendar>
      </v-col>
    </v-row>

    <!-- 出勤登録キャストリスト -->
    <section class="shift-cast-list">
      <v-row
        class="mt-5"
        v-if="!casts.length"
      >
        <v-col cols="12">
          <v-card flat>
            <v-card-text>
              表示するデータがありません。
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <ul class="pa-0">
        <li cols="12"
          class="d-flex my-4"
          v-for="(cast, index) in casts"
          :key="cast.cast_id"
        >
          <!-- 画像 -->
          <v-card class="hero d-flex flex-column">
            <v-img
              :src="cast.image_url"
              cover
              width="80"
              max-height="120"
            ></v-img>
            <v-avatar
              v-if="cast.is_dummy"
              size="25"
              color="icon-dummy grey"
            >
              <v-icon small>
                mdi-drama-masks
              </v-icon>
            </v-avatar>
            <p class="pt-1 pl-1 primary rounded-b text-caption">{{ cast.name }}</p>
            <!-- 一括登録 -->
            <v-btn
              fab small
              color="accent"
              @click="openFormRegister('create', index)"
            >
              <v-icon>mdi-calendar-range</v-icon>
            </v-btn>
          </v-card>

          <ul class="week-row ml-2 py-3 flex-grow-1 d-flex justify-space-between rounded">
            <li class="date-shift d-flex flex-column flex-grow-1 align-center"
              v-for="(shift, idx) in cast.weekShifts"
              :key="idx + cast.cast_id"
              :data-id="shift.shift_id"
            >
              <header :class="{ 'shift-in': shift.start_at !== shift.end_at }">
                {{ jpDate(shift.shift_date) }}
              </header>

              <div class="mt-2 mb-3">
                <div v-if="shift.start_at === shift.end_at">
                  <p
                    class="input-shift px-7 primary--text"
                    @click="inputShift(shift)"
                  >
                    出勤入力
                  </p>
                </div>
                <div v-else class="time-row">
                  <!-- タイムピッカー -->
                  <template>
                    <time-picker
                      class="text-overline"
                      :value="getHHmm(shift.start_at)"
                      :hour-range="shiftHourRange"
                      format="HH:mm"
                      placeholder="開始"
                      hour-label="時"
                      minute-label="分"
                      minute-interval="30"
                      auto-scroll
                      close-on-complete
                      hide-disabled-hours
                      hide-disabled-minutes
                      hide-clear-button
                      @close="setShiftDateOnInput(shift, 'start', $event)"
                    ></time-picker>
                  </template>
                  <span> ~ </span>
                  <template>
                    <time-picker
                      class="text-overline"
                      :value="getHHmm(shift.end_at)"
                      :hour-range="shiftHourRange"
                      format="HH:mm"
                      placeholder="終了"
                      hour-label="時"
                      minute-label="分"
                      minute-interval="30"
                      auto-scroll
                      close-on-complete
                      hide-disabled-hours
                      hide-disabled-minutes
                      hide-clear-button
                      @close="setShiftDateOnInput(shift, 'end', $event)"
                    ></time-picker>
                  </template>
                </div>
              </div>

              <div class="d-flex mt-auto">
                <button
                  class="btn-shift update-shift primary"
                  :disabled="shift.update_disabled"
                  @click="updateClicked(shift)"
                >
                  更新
                </button>
                <button
                  class="btn-shift delete-shift ml-2"
                  :disabled="isInOutSame(shift)"
                  @click="deleteClicked(shift)"
                >
                  削除
                </button>
              </div>
            </li>
          </ul>

        </li>
      </ul>
    </section>

    <!-- 一括登録フォーム -->
    <form-register
      ref="formRegister"
      :apiAdmin="apiAdmin"
      :shopData="shopData"
      :shiftHourRange="shiftHourRange"
      @reset="$emit('reset')"
    ></form-register>

    <!-- 確認モーダル -->
    <modal-confirm ref="modalConfirm">
      <div v-html="modalMessage"></div>
    </modal-confirm>

    <!-- オーバーレイメッセージ -->
    <overlay-message ref="overlayMessage">
      <div v-html="modalMessage"></div>
    </overlay-message>

    <!-- ローダー -->
    <loader
      :loading="loading"
      :absolute="false"
    >
      {{ loadingMessage }}
    </loader>

    <!-- スナックバー -->
    <v-snackbar
      v-model="snackbar.open"
      :timeout="3000"
      :color="snackbar.color"
      top
    >
      {{ snackbar.message }}
    </v-snackbar>

  </v-container>
</template>

<!-- ************************************* -->
<!-- ************* スクリプト ************** -->
<!-- ************************************* -->
<script>
import moment from 'moment'
import 'moment/locale/ja'
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'

import $literals from '@/literals.js'
import { CheckTokenError, ApiTool, BizHour } from '@/module.js'
import Loader from '@/components/_Loader.vue'
import ContentInfoBar from '@/components/_ContentInfoBar.vue'
import ModalConfirm from '@/components/_ModalConfirm.vue'
import OverlayMessage from '@/components/_OverlayMessage.vue'
import FormRegister from '@/components/ShiftFormRegister.vue'
import CastsInCalendar from '@/components/_CastsInCalendar.vue'
import CastsInDate from '@/components/_CastsInDate.vue'

export default {
  components: {
    'time-picker': VueTimepicker,
    'loader': Loader,
    'info-bar': ContentInfoBar,
    'modal-confirm': ModalConfirm,
    'overlay-message': OverlayMessage,
    'form-register': FormRegister,
    'shift-calendar': CastsInCalendar,
    'casts-in-date': CastsInDate,
  },

  props: {
    apiAdmin: {
      type: String,
      required: true
    },
    shopData: {
      type: Object,
      required: true
    }
  },

  //***************************************************
  //データ
  //***************************************************
  data() {
    return {
      casts: [],
      shiftHourRange: [],
      displayFromDate: new Date(),
      dateForHeaderLable: '本日',
      castsInCount: 0,
      displayingCastsInDate: '',
      scrollAmount: 250,
      changedOrder: false,
      modalMessage: '',
      shiftLoading: false,
      loading: false,
      loadingMessage: '',
      snackbar: {open: false, color: 'primary', message: ''},
      adminApi: new ApiTool(this.apiAdmin, this.shopData),
      bizHour: new BizHour(this.shopData)
    }
  },

  //***************************************************
  //算出
  //***************************************************
  computed: {
    serverToken() {
      return sessionStorage.getItem('serverToken')
    },
    jpDate() {
      return date => moment(date).format('M/D (dd)')
    },
    getHHmm() {
      return date => moment(date).format('HH:mm')
    },
    isInOutSame() {
      return shift => shift.start_at === shift.end_at
    },
    initialCastsInDate() {
      return this.bizHour.getBizOpening(new Date())
    }
  },

  //***************************************************
  //ナビゲーションガード
  //***************************************************
  beforeRouteLeave(to, from, next) {
    if (this.changedOrder) {
      this.modalMessage = '<p>変更した並び順を登録してない様ですがページを移動してよろしいですか？</p>'

      const modalHanddown = {
        yesCallback: next,
        buttonLabel: '移動する',
      }
      this.$refs.modalConfirm.open(modalHanddown)
    } else {
      next()
    }
  },

  //***************************************************
  //ライフサイクル
  //***************************************************
  created() {
    this.adminApi.setToken(this.serverToken)

    //タイムピッカー表示用の時刻配列のセット
    this.setShiftHourRange()

    //出勤リストの日付
    this.displayingCastsInDate = this.initialCastsInDate

    this.loading = true
    this.loadingMessage = 'キャストデータ取得中・・・'

    this.getInitData()
    .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
    .then(() => this.loading = false)
  },

  //***************************************************
  //メソッド
  //***************************************************
  methods: {
    async getInitData() {
      await this.getCasts()
      await this.setShiftRecords(new Date(), 7)
    },

    //タイムピッカー表示用の時刻配列のセット
    setShiftHourRange() {
      //日またぎ営業店考慮
      if (this.bizHour.closingHourNum <= this.bizHour.openingHourNum) {
        this.shiftHourRange.push([this.bizHour.openingHourNum, 23])
        this.shiftHourRange.push([0, this.bizHour.closingHourNum])
      } else {
        this.shiftHourRange.push(this.bizHour.openingHourNum)
        this.shiftHourRange.push(this.bizHour.closingHourNum)
      }
    },

    //出勤登録リストのスクロール
    scrollLeft() {
      document.querySelector('.shift-cast-list').scrollLeft -= this.scrollAmount
    },
    scrollRight() {
      document.querySelector('.shift-cast-list').scrollLeft += this.scrollAmount
    },

    //週間パジネーション
    weekNation(direction) {
      if (direction === 'forward') {
        this.displayFromDate = moment(this.displayFromDate).add(7, 'd').format('YYYY-MM-DD')
      } else {
        this.displayFromDate = moment(this.displayFromDate).subtract(7, 'd').format('YYYY-MM-DD')
      }

      this.setShiftRecords(this.displayFromDate, 7)
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
    },

    //カレンダーの日付変更イベント
    changeCastsInDate(date) {
      this.$refs.castsInDate.setShiftsOfDay(date)
      this.dateForHeaderLable = date === moment(new Date()).format('YYYY-MM-DD') ? '本日' : moment(date).format('D') + '日'
      this.displayingCastsInDate = date
    },

    //ヘッダーラベル更新
    updateCastsInCount(castsInEmit) {
      this.castsInCount = castsInEmit.count
    },

    //タイムピッカークローズ時に決定入力値を vue data に戻す
    setShiftDateOnInput(shiftData, type, pickerInput) {
      //入力値が24時以降なら+1日（日またぎ営業店考慮）
      if ( pickerInput.data.k < this.bizHour.openingHourNum || pickerInput.data.k == 24 ) {
        shiftData[type + '_at'] = moment(shiftData.shift_date).add(1, 'd').format('YYYY-MM-DD') + ' ' + pickerInput.displayTime
      } else {
        shiftData[type + '_at'] = moment(shiftData.shift_date).format('YYYY-MM-DD') + ' ' + pickerInput.displayTime
      }

      //時刻バリデーション
      if (moment(shiftData.start_at).isSameOrAfter(moment(shiftData.end_at))) {
        shiftData.update_disabled = true

        this.snackbar = {...{color:'warning', message: $literals.MESSAGE.validationStartEndTime, open: true}}
      } else {
        shiftData.update_disabled = false
      }
    },

    //出勤入力クリック
    inputShift(shiftData) {
      shiftData.start_at = this.bizHour.getBizOpening(shiftData.start_at)
      shiftData.end_at = this.bizHour.getBizClosing(shiftData.start_at)
      shiftData.update_disabled = false
    },

    //
    //更新クリック
    //
    updateClicked(shiftData) {
      if (moment(shiftData.start_at).isSameOrAfter(moment(shiftData.end_at))) {
        this.snackbar = {...{color:'warning', message: $literals.MESSAGE.validationStartEndTime, open: true}}
        return
      }

      if (shiftData.shift_id) this.updateShiftRecord(shiftData)
      else this.createShiftRecord(shiftData)
    },

    //
    //削除クリック
    //
    deleteClicked(shiftData) {
      if (shiftData.shift_id) {
        this.deleteCastShift(shiftData)
      } else {
        //start_atと end_atを同じにするとピッカーが時刻未入力状態に変わる
        shiftData.start_at = this.bizHour.getBizOpening(shiftData.start_at)
        shiftData.end_at = this.bizHour.getBizOpening(shiftData.start_at)
        shiftData.update_disabled = true
      }
    },

    //一括登録クリック
    openFormRegister(type, index) {
      const formHanddown = {
        formType: type,
        updateData: this.casts[index],
        submitCallback: this.createRangeShiftRecord,
        comeBack: { index: index }
      }
      this.$refs.formRegister.open(formHanddown)
    },

    //**************************************************
    //**************************************************
    //                    APIコール
    //**************************************************
    //**************************************************
    //キャストリスト用の出勤データ取得
    //**************************************************
    getCasts() {
      return this.adminApi.getReqWithAuth('cast/').then( results => {
        if (!results || !results.length) {
          this.modalMessage = '<p>キャストが一人も登録されていません。<br />「店舗管理」→「キャスト管理」から登録してください。</p>'
          this.$refs.overlayMessage.open()
          return
        }

        results.map( cast => {
          if (cast.is_active) this.casts.push({ ...cast, weekShifts: [] })
        })
      })
    },

    //**************************************************
    //出勤データ取得
    //**************************************************
    setShiftRecords(fromDate, days) {
      this.loading = true
      this.loadingMessage = '出勤データ取得中・・・'

      const query = {
        fromDate: this.bizHour.getBizOpening(fromDate),
        toDate: this.bizHour.getBizClosing(moment(fromDate).add(days, 'd'))
      }

      return this.adminApi.getReqWithAuth('shift/', query).then( results => {
        this.casts.map(cast => {
          const dummyDate = moment(this.bizHour.getBizOpening(fromDate)).clone()

          //日数分ダミーのシフトデータを用意
          cast.weekShifts.length = 0
          for (let i = 0; i < days; i++) {
            cast.weekShifts.push({
              shop_id: cast.shop_id,
              cast_id: cast.cast_id,
              shift_date: dummyDate.format('YYYY-MM-DD'),
              start_at: dummyDate.format('YYYY-MM-DD HH:mm'),
              end_at: dummyDate.format('YYYY-MM-DD HH:mm'),
              update_disabled: true
            })
            dummyDate.add(1, 'd')
          }

          if (!results || !results.length) return

          //取得した出勤データでダミーシフトを上書き
          results.map( shiftRecord => {
            if (shiftRecord.cast_id === cast.cast_id) {
              const index = cast.weekShifts.findIndex(shift => {
                return ( moment(shift.shift_date).format('YYYYMMDD') === moment(shiftRecord.shift_date).format('YYYYMMDD') )
              })
              if (index > -1) {
                //入れ子オブジェクトのリアクティブ保持のためオブジェクトコピーせずそれぞれのプロパティに値を移す
                cast.weekShifts[index].shift_id = shiftRecord.shift_id
                cast.weekShifts[index].shop_id = shiftRecord.shop_id
                cast.weekShifts[index].cast_id = shiftRecord.cast_id
                cast.weekShifts[index].display_order = shiftRecord.display_order
                cast.weekShifts[index].shift_date = shiftRecord.shift_date
                cast.weekShifts[index].start_at = moment(shiftRecord.start_at).format('YYYY-MM-DD HH:mm')
                cast.weekShifts[index].end_at = moment(shiftRecord.end_at).format('YYYY-MM-DD HH:mm')
              }
            }
          })
        })

        this.loading = false
      })
    },

    //**************************************************
    //出勤データ新規登録
    //**************************************************
    createShiftRecord(shiftData) {
      this.shiftLoading = true

      const apiPartial = 'shift/create/cast/' + shiftData.cast_id
      const data = {
        shift_date: moment(shiftData.shift_date).format('YYYY-MM-DD'),
        start_at: shiftData.start_at,
        end_at: shiftData.end_at
      }

      this.adminApi.apiReqWithData('POST', apiPartial, JSON.stringify(data)).then( response => {
        if (response) {
          shiftData.shift_id = response.shift_id
          shiftData.display_order = response.display_order
          shiftData.update_disabled = true
        }

        //カレンダーの出勤数更新
        this.$refs.shiftCalendar.getMonthlyCounts(shiftData.shift_date)

        //◯日出勤一覧の日付と同じなら再取得
        if (moment(shiftData.shift_date).format('YYYY-MM-DD') === moment(this.displayingCastsInDate).format('YYYY-MM-DD')) {
          this.$refs.castsInDate.setShiftsOfDay(this.displayingCastsInDate)
        }

        this.snackbar = {...{color:'success', message: $literals.MESSAGE.successCreateSubmit, open: true}}
      })
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
      .then(() => this.shiftLoading = false )
    },

    //**************************************************
    //出勤データ更新
    //**************************************************
    updateShiftRecord(shiftData) {
      this.shiftLoading = true

      const apiPartial = 'shift/update/cast/' + shiftData.cast_id + '/' + shiftData.shift_id
      const data = {start_at: shiftData.start_at, end_at: shiftData.end_at}

      this.adminApi.apiReqWithData('PUT', apiPartial, JSON.stringify(data)).then(() => {
        shiftData.update_disabled = true

        //◯日出勤一覧の日付と同じなら再取得
        if (moment(shiftData.shift_date).format('YYYY-MM-DD') === moment(this.displayingCastsInDate).format('YYYY-MM-DD')) {
          this.$refs.castsInDate.setShiftsOfDay(this.displayingCastsInDate)
        }

        this.snackbar = {...{color:'success', message: $literals.MESSAGE.successUpdateSubmit, open: true}}
      })
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
      .then(() => this.shiftLoading = false )
    },

    //**************************************************
    //出勤データ一括更新
    //**************************************************
    createRangeShiftRecord(formData, cameBackData) {
      const apiPartial = 'shift/create/range/cast/' + cameBackData.cast_id

      this.adminApi.apiReqWithData('POST', apiPartial, formData).then(() => {
        const displayDate = moment(this.displayingCastsInDate)

        //出勤データと出勤統計カレンダーの再取得
        this.setShiftRecords(cameBackData.fromDate, 7)
        this.$refs.shiftCalendar.getMonthlyCounts(cameBackData.fromDate)

        //◯日出勤一覧の日付が範囲内なら再取得
        if (displayDate.isSameOrAfter(cameBackData.fromDate) && displayDate.isSameOrBefore(cameBackData.toDate)) {
          this.$refs.castsInDate.setShiftsOfDay(displayDate)
        }

        this.snackbar = {...{color:'success', message: $literals.MESSAGE.successUpdateSubmit, open: true}}
      })
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
    },

    //**************************************************
    //出勤データ削除
    //**************************************************
    deleteCastShift(shiftData) {
      this.shiftLoading = true

      const apiPartial = 'shift/delete/cast/' + shiftData.cast_id + '/' + shiftData.shift_id

      this.adminApi.apiReqWithData('DELETE', apiPartial).then(() => {
        //shift_idを削除して時刻初期状態に戻す
        shiftData.shift_id = ''
        shiftData.start_at = this.bizHour.getBizOpening(shiftData.start_at)
        shiftData.end_at = this.bizHour.getBizOpening(shiftData.start_at)

        //カレンダーの出勤数更新
        this.$refs.shiftCalendar.getMonthlyCounts(shiftData.shift_date)

        //◯日出勤一覧の日付と同じなら再取得
        if (moment(shiftData.shift_date).format('YYYY-MM-DD') === moment(this.displayingCastsInDate).format('YYYY-MM-DD')) {
          this.$refs.castsInDate.setShiftsOfDay(this.displayingCastsInDate)
        }

        shiftData.update_disabled = true
        this.snackbar = {...{color:'success', message: $literals.MESSAGE.successDeleteSubmit, open: true}}
      })
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
      .then(() => this.shiftLoading = false )
    }
  }
}
</script>

<!-- ************************************* -->
<!-- ************** スタイル ************** -->
<!-- ************************************* -->
<style scoped>
.shift-cast-list {
  overflow-x: scroll;
}
.week-row {
  padding-left: 0;
  box-shadow: 0px 1px 1px -2px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%),
    0px 1px 1px 0px rgb(0 0 0 / 12%);
}
.hero {
  position: relative;
}
.hero .v-btn {
  position: absolute;
  top: 5px;
  right: -24px;
}
.icon-dummy {
  position: absolute;
  top: 2px;
  left: 2px;
}
.date-shift {
  min-width: 150px;
}
.date-shift:last-child {
  border: none !important;
}
.theme--light .date-shift {
  border-right: thin solid var(--content-bg-diff);
}
.theme--dark .date-shift {
  border-right: thin solid black;
}
.theme--light .week-row {
  background-color: white;
}
.theme--dark .week-row {
  background-color: var(--content-bg-dark-diff);
  border: thin solid var(--content-border-dark);
}
.input-shift {
  padding: 5px;
  font-size: .8em;
  border: thin solid var(--v-primary-base);
  border-radius: 5px;
}
.input-shift:hover {
  color: var(--v-accent-base) !important;
}
.btn-shift {
  padding: 3px 13px;
  font-size: .8em;
  border-radius: 3px;
  cursor: pointer;
}
.btn-shift:disabled {
  cursor: auto;
  color: grey;
}
.update-shift:disabled {
  background-color: transparent !important;
}
.delete-shift:not(:disabled) {
  /* color: var(--v-primary-base); */
  border: thin solid var(--v-primary-darken1);
}

/* time-picker */
>>> .vue__time-picker {
  width: unset;
}
>>> .vue__time-picker input.display-time {
  width: 60px;
  height: 28px;
  font-size: 1.1em;
  font-weight: bold;
  color: var(--v-accent-base);
  text-align: center;
  border: none;
  border-radius: 4px;
  background-color: var(--v-primary-base);
}
>>> .vue__time-picker .drop-down,
>>> .vue__time-picker .select-list {
  width: 140px;
  height: 200px;
  border-radius: 5px;
}
>>> .vue__time-picker .dropdown ul li:not([disabled]).active {
  background: var(--v-primary-base);
}
>>> .vue__time-picker .display-time {
  color: white !important;
  font-weight: 400 !important;
}
</style>
