<template>
  <v-sheet
    class="casts-calendar"
    :height="$attrs.height"
    color="transparent"
  >
    <v-row no-gutters>
      <v-col class="pa-0">
        <v-card>
          <v-calendar
            class="custom-calendar"
            ref="calendar"
            :attributes="statisticsAttributes"
            :masks="calendarMask"
            is-expanded
            @update:from-page="loadStatistics"
          >
            <template v-slot:day-content="{ day, attributes }">
              <div
                class="day-cell"
                @click="getStatisticsOfDate(day, $event)"
              >
                <span class="day-label">{{ day.day }}</span>
                <div
                  class="custom-contenmt"
                  v-for="attr in attributes"
                  :key="attr.key"
                >
                  <!-- キャストカウント -->
                  <v-avatar
                    class="white--text mr-1"
                    v-if="attr.customData.realCount"
                    size="20"
                    color="accent"
                  >
                    {{ attr.customData.realCount }}
                  </v-avatar>
                  <!-- ダミー -->
                  <v-avatar
                    class="white--text"
                    v-if="attr.customData.dummyCount"
                    size="20"
                    color="grey"
                  >
                    {{ attr.customData.dummyCount }}
                  </v-avatar>
                </div>
              </div>
            </template>
          </v-calendar>

          <!-- ローダー -->
          <v-progress-linear
          :active="loading"
          :indeterminate="loading"
          absolute top
          color="primary"
          ></v-progress-linear>

        </v-card>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<!-- ************************************* -->
<!-- ************* スクリプト ************** -->
<!-- ************************************* -->
<script>
import moment from 'moment'
import VCalendar from 'v-calendar/lib/components/calendar.umd' //https://vcalendar.io

import { ApiTool, CheckTokenError } from '@/module.js'

export default {
  components: {
    'v-calendar': VCalendar
  },

  props: {
    apiAdmin: {
      type: String,
      required: true
    },
    shopData: {
      type: Object,
      required: true
    },
  },

  //***************************************************
  //データ
  //***************************************************
  data() {
    return {
      calendarDate: '',
      loading: false,
      adminApi: new ApiTool(this.apiAdmin, this.shopData),
      statisticsAttributes: [],
      calendarMask: { title: 'YYYY年M月の出勤統計' },
    }
  },

  //***************************************************
  //算出
  //***************************************************
  computed: {
    serverToken() {
      return sessionStorage.getItem('serverToken')
    },
  },

  //***************************************************
  //ライフサイクル
  //***************************************************
  created() {
    this.adminApi.setToken(this.serverToken)

    //月間出勤統計の取得はV-Calendarロード時に読み込まれている
    // this.getMonthlyCounts(new Date())
  },

  mounted() {
    //当日 day-cellに activeクラス付与
    const el = document.querySelector('.custom-calendar .is-today .day-cell')
    el.classList.add('date-active')
  },

  //***************************************************
  //メソッド
  //***************************************************
  methods: {
    //カレンダーの表示月を移動したら再取得
    loadStatistics(day) {
      const getDateStr = day.year + '-' + ('0' + day.month).slice(-2) + '-01T' + this.shopData.opening_hour
      this.getMonthlyCounts(getDateStr)
    },

    //出勤統計カレンダーの日セルクリック
    getStatisticsOfDate(calendarDate, event) {
      this.$emit('changeCalendarDate', moment(calendarDate.date).format('YYYY-MM-DD'))

      //セルのclassセット
      const els = document.querySelectorAll('.custom-calendar .day-cell')
      els.forEach( dayCell => dayCell.classList.remove('date-active') )
      event.currentTarget.classList.add('date-active')
    },

    //**************************************************
    //**************************************************
    //                    APIコール
    //**************************************************
    //**************************************************
    //月間出勤統計の取得
    //**************************************************
    getMonthlyCounts(date) {
      this.loading = true

      const query =  {
        fromDate: moment(date).startOf('month').format('YYYY-MM-DD'),
        toDate: moment(date).endOf('month').format('YYYY-MM-DD')
      }

      this.adminApi.getReqWithAuth('shift/count/', query).then( results => {
          this.statisticsAttributes.length = 0

          //現在表示月でないなら月移動
          if (moment(this.calendarDate).format('YYYY-MM') !== moment(date).format('YYYY-MM')) {
            this.$refs.calendar.move(date)
          }

          this.calendarDate = moment(date).format()

          if (!results) return

          //V-calendar用のアトリビュートを設定
          results.map( dayData => {
            this.statisticsAttributes.push({
              key: dayData.shift_date,
              dates: new Date(dayData.shift_date),
              customData: {realCount: dayData.real_count, dummyCount: dayData.dummy_count}
            })
          })
        })
        .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
        .then(() => this.loading = false)
    },
  }
}
</script>

<!-- ************************************* -->
<!-- ************** スタイル ************** -->
<!-- ************************************* -->
<style scoped>
>>> .v-badge__badge {
  position: initial;
}

/* v-calendar用 */
.custom-calendar.vc-container {
  border: none;
}
>>> .vc-header {
  background-color: var(--v-primary-base);
  border-bottom: 1px solid #ddd;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
>>> .vc-arrow {
  color: var(--v-accent-base);
}
>>> .vc-weeks {
  padding: 0 !important;
}
>>> .vc-day {
  border-top: 1px solid #ddd;
  border-left: 1px solid #ddd;
}
>>> .vc-day.on-left {
  border-left: none;
}
>>> .vc-day.weekday-1,
>>> .vc-day.weekday-7 {
  background-color: var(--v-secondary-lighten1);
}
>>> .vc-day.is-today .day-label {
  padding: 3px 7px;
  border-radius: 3px;
  color: white;
  background-color: var(--v-accent-lighten2);
}
>>> .day-cell.date-active {
  background-color: var(--v-primary-base);
}
>>> .vc-day .day-cell {
  min-height: 50px;
  padding: 5px;
  cursor: pointer;
}
>>> .vc-day .day-cell:hover {
  background-color: var(--v-primary-lighten1);
}
>>> .vc-day.on-bottom.on-left {
  border-bottom-left-radius: 3px;
}
>>> .vc-day.on-bottom.on-right {
  border-bottom-right-radius: 3px;
}
</style>
